import {
  defaultNestedFormsOptions,
  incrementIndexesOfImageUploadElements,
  initClassicEditor,
  resetImagePreview
} from './_nested_forms';

$(function () {
  if ($('#nested-vouchers').length) {
    const initNestedMediaContents = ($nestedForm) => {
      const timestamp = Date.now();

      $nestedForm.find('.nested-media-voucher').addClass(`nested-media-voucher-${timestamp}`);
      $nestedForm.find('.nested-medium-form').addClass(`nested-medium-form-${timestamp}`);
      $nestedForm.find('.nested-add-medium').addClass(`nested-add-medium-${timestamp}`);

      $nestedForm.find(`.nested-media-voucher-${timestamp}`).nestedForm({
        forms: `.nested-medium-form-${timestamp}`,
        adder: `.nested-add-medium-${timestamp}`,
        ...defaultNestedFormsOptions,
        beforeAddForm: ($container) => {
          $container.children(`.nested-medium-form-${timestamp}`).removeClass('d-none');
        },
        afterAddForm: (_$container, $form) => {
          incrementIndexesOfImageUploadElements($form);
          resetImagePreview($form);
        },
        associations: 'media_contents' // needed to correctly increment ids of added sections
      });
    };

    $('#nested-vouchers').nestedForm({
      forms: '.nested-voucher-form',
      adder: '#nested-add-voucher',
      remover: '.remove-voucher',
      postfixes: '',
      afterRemoveForm: ($form) => {
        $form.find('input[name$="[visible]"]').val('false');
      },
      afterInitialize: () => {
        const $initialForms = $('.nested-voucher-form');

        $initialForms.each((_index, form) => {
          initNestedMediaContents($(form));
        });
      },
      beforeAddForm: ($container, $form) => {
        $container.children('.nested-voucher-form:not(.invisible)').removeClass('d-none');

        // we only want one initialized media content, so remove eventually created others
        $form.find('.nested-medium-form').each((index, form) => {
          if (index > 0) {
            $(form).remove();
          }
        });
      },
      afterAddForm: (_$container, $form) => {
        initNestedMediaContents($form);
        incrementIndexesOfImageUploadElements($form);

        // init html editors for voucher field text
        $form
          .get('0')
          .querySelectorAll('.html-editor')
          .forEach((htmlEditor) => initClassicEditor(htmlEditor));

        // clear date inputs because normally just texts and textareas are cleared
        $form.find('input[type="date"]').val('');
        resetImagePreview($form);
      }
    });
  }
});
